<script setup lang="ts">
import TheSidebar from "~/components/ui/TheSidebar.vue";
import InactivityMonitor from "~/components/InactivityMonitor.vue";

const isSidebarOpen = ref(true);
</script>

<template>
  <VApp>
    <InactivityMonitor />
    <VLayout class="tw-overflow-visible">
      <TheSidebar v-model="isSidebarOpen" />
      <VAppBar color="primary" flat density="comfortable">
        <VAppBarNavIcon class="lg:!tw-hidden" @click="isSidebarOpen = true" />
        <template #append />
      </VAppBar>
      <VMain>
        <slot />
      </VMain>
    </VLayout>
  </VApp>
</template>
