<script setup lang="ts">
import { useSnackbarStore } from "~/store/useSnackbarStore";

const INACTIVITY_TIMEOUT = 1000 * 60 * 15;

const router = useRouter();

let timer: number | undefined;

const resetTimer = useDebounce(() => {
  if (timer) {
    clearTimeout(timer);
  }
  timer = window.setTimeout(() => {
    onInactive();
  }, INACTIVITY_TIMEOUT);
}, 1000);

function onInactive() {
  useAuth().signOut();
  router.push("/auth/login");
  useSnackbarStore().show(
    "You have been logged out due to inactivity",
    "warning",
    true,
  );
}

const setupEventListeners = () => {
  window.addEventListener("keydown", resetTimer);
  window.addEventListener("scroll", resetTimer);
  window.addEventListener("click", resetTimer);
};

const removeEventListeners = () => {
  window.removeEventListener("keydown", resetTimer);
  window.removeEventListener("scroll", resetTimer);
  window.removeEventListener("click", resetTimer);
};

onMounted(() => {
  setupEventListeners();
  resetTimer();
});

onBeforeUnmount(() => {
  removeEventListeners();
  if (timer) {
    clearTimeout(timer);
  }
});
</script>

<template>
  <slot />
</template>
